import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    sideBarTopText:"My Account",
}

export const sidebarHeaderSettingsSlice = createSlice({
    name: 'sidebarHeaderSettings',
    initialState,
    reducers: {
        setSideBarTopText: (state, action) => {
            state.sideBarTopText = action.payload
        },
    }
})

export const { setSideBarTopText } = sidebarHeaderSettingsSlice.actions;

export default sidebarHeaderSettingsSlice.reducer;