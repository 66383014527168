import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isButtonClicked: false,
};

export const commonSettings = createSlice({
    name: 'commonSettings',
    initialState,
    reducers: {
        setIsButtonClicked: (state, action) => {
            state.isButtonClicked = action.payload;
        },
    },
});

export const {
    setIsButtonClicked
} = commonSettings.actions;

export default commonSettings.reducer;
