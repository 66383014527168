import { configureStore } from '@reduxjs/toolkit';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { persistStore, persistReducer } from 'redux-persist'; // imports from redux-persist
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import querySetsReducer from './quersets/reducers';
import settingReducer from './setting/reducers';
import subscriptionReducer from './subscription/reducers';

import reportReducer from './reports/reducers';
import dashboardReducer from './Dashboard/reducers';
import dataSourceReducer from './Datasource/reducers';
import sidebarHeaderSettingsReducer from './sidebarHeaderSettings/reducers';
import formReducer from './authentication/reducers';
import profileSlice from './profile/reducer';
import commonSettings from './commonSettings/reducer';
import aiChatBot from './aiChatbot/reducers';
import aiCalendar from './aiCalendar/reducers';

import { productReducer } from './ecommerce/product/productSlice';


const persistConfig = {
  // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_SECRET_KEY_REDUX || 'shopdot',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const combinedReducer = combineReducers({
  setting: settingReducer,
  subscription: subscriptionReducer,
  datasource: dataSourceReducer,
  sidebarHeaderSettings: sidebarHeaderSettingsReducer,
  formConfig: formReducer,
  profile: profileSlice,
  commonSettings: commonSettings,
  aiChatBot: aiChatBot,
  product: productReducer,
  dashboard: dashboardReducer,
  querysets: querySetsReducer,
  aiCalendar: aiCalendar,
  reports: reportReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return combinedReducer(undefined, { type: undefined });
  } else {
    return combinedReducer(state, action);
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer

const store = configureStore({
  reducer: persistedReducer
});

const persistor = persistStore(store);


export { store, persistor };


