import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Image,
  Navbar,
  Overlay,
  Popover,
} from "react-bootstrap";
import home from "../../../../assets/images/brands/home.svg";
import logoutImage from "../../../../assets/images/brands/logout.svg";
import { useAuth } from "../../../../utils/AuthContext";
import { useNavigate } from "react-router-dom";
import account from "../../../../assets/images/brands/account.svg";
import { getUser } from "../../../../utils/localStorage";
import "../../styles/aichatbotSettings.css"
const ImageComponent = lazy(() =>
  import("../CommonComponents/ImageComponent")
);

function AISubscriptionHeader({ leftLabel }) {
  const [showPopover, setShowPopover] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const popoverRef = useRef(null);
  const targetRef = useRef(null);
  const user = getUser();

  const handleSignOut = () => {
    logout();
    setShowPopover(false);
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setShowPopover(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const popover = (
    <Popover id="popover-basic" className="popup-container" ref={popoverRef}>
      <Popover.Header as="h3" className="popup-header">
        <div className="d-flex align-items-center gap-3">
          <div className="popup-header-container">
            <Image src={account} alt="account" width="30px" height="30px" />
          </div>
          <p className="m-0">
            {user?.user?.first_name} {user?.user?.last_name}
          </p>
        </div>
      </Popover.Header>
      <Popover.Body className="d-flex justify-content-between align-items-center p-2 popup-body">
        <p
          className="m-0 popup-body-heading"
          onClick={() => {
            navigate("/my-account/profile");
            setShowPopover(false);
          }}
        >
          My Account
        </p>
        <Button
          type="button"
          variant="secondary"
          onClick={handleSignOut}
          className="popup-body-signup-btn"
        >
          Sign out
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Navbar
        bg="white"
        expand="lg"
        className="border-bottom subscription-header-container w-100 px-3"
      >
        <Container fluid>
          <div className="text-dark fw-bolder fs-3 w-2">
            {leftLabel}
          </div>
          <div>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll" className="justify-content-end">
              <div className="d-flex gap-3">
                <div
                  className="chatbot-settings-home-logout-icons"
                  onClick={() => navigate("/")}
                >
                  <ImageComponent
                    src={home}
                    className="img-fluid"
                    alt="authentication"
                  />
                </div>
                <div
                  ref={targetRef}
                  className="chatbot-settings-home-logout-icons"
                  onClick={() => setShowPopover(!showPopover)}
                >
                  <Suspense fallback={<div className="react-load"></div>}>
                    <ImageComponent
                      src={logoutImage}
                      className="img-fluid"
                      alt="authentication"
                    />
                  </Suspense>
                </div>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
        <Overlay
          show={showPopover}
          target={targetRef.current}
          placement="bottom"
          containerPadding={20}
          rootClose={true}
          rootCloseEvent="mousedown"
        >
          {popover}
        </Overlay>
      </Navbar>
    </>
  );
}

export default AISubscriptionHeader;
