import { createSlice } from '@reduxjs/toolkit';
import { aiCalendarReducerState } from '../../shared/constants/initialStates'

export const aiCalendarSlice = createSlice({
    name: 'aiCalendar',
    initialState: aiCalendarReducerState,
    reducers: {
        setCalendarAvailabilityType: (state, action) => {
            state.calendarAvailabilityType = action.payload;
        },
        setCalendarCurrentTab: (state, action) => {
            state.calendarCurrentTab = action.payload;
        },
        setSingleAvailability(state, action) {
            state.single = action.payload;
        },
        setSingleDateAvailability(state, action) {
            state.single.date = action.payload;
        },
        addSingleSlot(state, action) {
            state.single.slots.push(action.payload);  // Add a new empty slot
        },
        updateSingleSlot(state, action) {
            const { index, field, value } = action.payload;
            state.single.slots[index][field] = value;  // Update the specific slot
        },
        removeSingleSlot(state, action) {
            state.single.slots.splice(action.payload, 1);
        },
        setRecurringAvailability(state, action) {
            state.recurring[action.payload.day] = action.payload.slots;
        },
        removeSlot(state, action) {
            const { day, index } = action.payload;
            state.recurring[day].splice(index, 1);
        },
        setDefaultDuration(state, action) {
            state.settings.defaultDuration = action.payload;
        },
        setIsEmailVerified(state, action) {
            state.settings.isEmailVerified = action.payload;
        },
        setEmailAddress(state, action) {
            state.settings.emailAddress = action.payload;
        },
        setThankYouMessage(state, action) {
            state.settings.thankYouMessage = action.payload;
        },
        toggleCalendarIntegrationGoogle(state) {
            state.settings.calendarIntegrationGoogle = !state.settings.calendarIntegrationGoogle;
        },
        toggleCalendarIntegrationOutlook(state) {
            state.settings.calendarIntegrationOutlook = !state.settings.calendarIntegrationOutlook;
        },
        setActiveSettingsPage(state, action) {
            state.activeSettingsPage = action.payload;
        },
        setEndOn(state, action) {
            state.endOn = action.payload;
        },
        setTimeSlots(state, action) {
            state.timeSlots = action.payload;
        },
        setdistinctDates(state, action) {
            state.dateSlots = action.payload;
        },
        setSlotsDetails(state, action) {
            state.eventDetails = action.payload;
        },
        setSelectedSlotId(state, action) {
            state.selectedSlot.slotId = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedSlot.date = action.payload;
        },
    },
});

export const {
    setCalendarAvailabilityType,
    setSingleAvailability,
    setCalendarCurrentTab,
    setSingleDateAvailability,
    addSingleSlot,
    setdistinctDates,
    updateSingleSlot,
    removeSingleSlot,
    setDefaultDuration,
    setIsEmailVerified,
    setEndOnIsNever,
    setTimeSlots,
    setFormName,
    setFormEmail,
    setFormContactNumber,
    setFormAdditionalInfo,
    setSelectedSlotId,
    setSelectedDate,
    setEndOn,
    setEmailAddress,
    setThankYouMessage,
    toggleCalendarIntegrationGoogle,
    toggleCalendarIntegrationOutlook,
    setActiveSettingsPage,
    setRecurringAvailability,
    removeSlot,
    setSlotsDetails,
} = aiCalendarSlice.actions;

export default aiCalendarSlice.reducer;
