import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    product_variants_options: [],
    count: 0,
    variants: []
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setVariantOptions: (state, action) => {
            state.product_variants_options = action.payload
        },
        addBlankVariantOption: (state) => {
            state.product_variants_options = [
                ...state?.product_variants_options,
                {
                    option_name: "",
                    options: [{
                        value: ""
                    }],
                    save: false
                }
            ]
        },
        removeVariantOption: (state, action) => {
            let findOtherOptions = state?.product_variants_options.filter((item, index) => index != action.payload)
            state.product_variants_options = findOtherOptions
        },

        setVariantOptionName: (state, action) => {

            let variant_options = state?.product_variants_options?.map((item, index) => {

                if (index == action.payload.index) {
                    return {
                        ...item,
                        option_name: action.payload.name
                    }
                }
                else {
                    return {
                        ...item,
                    }
                }


            })

            state.product_variants_options = variant_options
        },

        setOptionValue: (state, action) => {
            let variant_options = state?.product_variants_options?.map((item, index) => {

                if (index == action.payload.variant_index) {

                    let options = item?.options?.map((item, index) => {

                        if (action.payload.option_index == null) {
                            return { ...item }

                        }
                        else {
                            if (index == action.payload.option_index) {
                                return {
                                    value: action?.payload?.value
                                }
                            }
                            else {
                                return { ...item }
                            }
                        }
                    })

                    if (action.payload.option_index == null) {
                        options.push({
                            value: action.payload.value
                        })
                    }

                    let lastValue = options[action.payload.option_index].value

                    if (lastValue && action.payload.option_index === options.length - 1) {
                        options.push({
                            value: ""
                        })
                    }

                    if (!lastValue && options.length > 1) {
                        options.pop();
                    }

                    return {
                        ...item,
                        options: options
                    }
                }
                else {
                    return {
                        ...item,
                    }
                }
            })

            state.product_variants_options = variant_options
        },

        setOptionSaved: (state, action) => {

            let variant_options = state?.product_variants_options?.map((item, index) => {

                if (index == action.payload.index) {
                    return {
                        ...item,
                        save: true
                    }
                }
                else {
                    return {
                        ...item,
                    }
                }


            })

            state.product_variants_options = variant_options
        },
        setOptionUnSaved: (state, action) => {
            let variant_options = state?.product_variants_options?.map((item, index) => {

                if (index == action.payload.index) {
                    return {
                        ...item,
                        save: false
                    }
                }
                else {
                    return {
                        ...item,
                    }
                }


            })

            state.product_variants_options = variant_options
        },
        setVariants: (state, action) => {
            state.variants = action.payload
        },
        setVariantsValue: (state, action) => {

            let variants = state?.variants?.map((item, index) => {

                if (index == action.payload.index) {
                    return {
                        ...item,
                        [action.payload.key]: action.payload.value
                    }
                }
                else {
                    return {
                        ...item,
                    }
                }
            })

            state.variants = variants
        },
        setVariantsImages: (state, action) => {

            let variants = state?.variants?.map((item, index) => {

                if (index == action.payload.index) {
                    return {
                        ...item,
                        variants_images: action.payload.images
                    }
                }
                else {
                    return {
                        ...item,
                    }
                }
            })

            state.variants = variants
        },
    }
})

export const { setVariantOptions, addBlankVariantOption, removeVariantOption, setVariantOptionName, setOptionValue, setOptionSaved, setOptionUnSaved, setVariants, setVariantsValue, setVariantsImages } = productSlice.actions;

export const productReducer = productSlice.reducer;

