import { KEYS } from "./constant";

const LocalStorage = {
    get: (key) => {
        if (typeof localStorage !== "undefined") {
            return localStorage.getItem(key);
        }

        return false;
    },

    getJSON: (key) => {
        if (typeof localStorage !== "undefined") {
            const data = LocalStorage.get(key);

            return data && data !== "undefined" ? JSON.parse(data) : "";
        }

        return false;
    },

    set: (...rest) => {
        if (typeof localStorage !== "undefined") {
            return localStorage.setItem(...rest);
        }

        return false;
    },

    setJSON: (key, value) => {
        if (typeof localStorage !== "undefined") {
            const data = JSON.stringify(value);

            return LocalStorage.set(key, data);
        }

        return false;
    },

    setToken: (token) => {
        // Iron.seal(token).then(encryptedToken =>
        //   LocalStorage.set(KEYS.authToken, encryptedToken)
        // )
        LocalStorage.set(KEYS.authToken, token);
    },

    setUser: (user) => {
        // Iron.seal(user).then(encryptedUser =>
        //   LocalStorage.set(KEYS.user, encryptedUser)
        // )
        LocalStorage.set(KEYS.user, JSON.stringify(user));
    },

    remove: (key) => {
        if (typeof localStorage !== "undefined") {
            return localStorage.removeItem(key);
        }

        return false;
    },

    clean: (key) => {
        if (typeof localStorage !== "undefined") {
            return localStorage.clear(key);
        }

        return false;
    },
};

const getToken = () => {
    // checking if localStorage exits in case we're using ssr
    if (typeof localStorage !== "undefined") {
        return LocalStorage.get(KEYS.authToken) || "";

        // try {
        //   if (typeof encryptedToekn === 'string' && encryptedToekn) {
        //     const token = Iron.unseal(encryptedToekn)

        //     return Promise.resolve(token)
        //   }

        //   return ''
        // } catch (error) {
        //   throw new Error(error)
        // }
    }

    return "";
};

const getUser = () => {
    // checking if localStorage exits in case we're using ssr
    if (typeof localStorage !== "undefined") {
        const encryptedUser = LocalStorage.get(KEYS.user);
        if (encryptedUser !== "undefined" && encryptedUser !== null) {
            return JSON.parse(encryptedUser);
        }
        return {};

        // try {
        //   if (typeof encryptedUser === 'string' && encryptedUser) {
        //     return await Iron.unseal(encryptedUser)
        //   }

        //   return {}
        // } catch (error) {
        //   throw new Error(error)
        // }
    }

    return {};
};

const getSessionUser = () => {
    if (typeof sessionStorage !== "undefined") {
        const encryptedUser = sessionStorage[KEYS.user];

        return JSON.parse(encryptedUser);

        // try {
        //   if (typeof encryptedUser === 'string' && encryptedUser) {
        //     const user = Iron.unseal(encryptedUser)

        //     return Promise.resolve(user)
        //   }

        //   return {}
        // } catch (error) {
        //   throw new Error(error)
        // }
    }

    return {};
};

export { LocalStorage, getUser, getToken, getSessionUser };
