import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DATA_SOURCE_APIS } from "../../utils/constant";
import fetchUrl from "../../api/index";
import toast from "react-hot-toast";

let configObj = { baseUrl: process.env.REACT_APP_URL, contentType: "application/json" };

export const fetchDataSource = createAsyncThunk(
    'dataSource/fetch',
    async () => {
        const type = 'get';
        const url = DATA_SOURCE_APIS.get;
        try {
            const response = await fetchUrl({ type, url, config: configObj });
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const addDataSource = createAsyncThunk(
    'dataSource/send',
    async (data) => {
        const type = 'post';
        const url = DATA_SOURCE_APIS.add;
        try {
            const response = await fetchUrl({ type, url, data, config: configObj });
            if (response?.status === 201) {
                toast.success("Database Added successfully!");
                return response.data;
            } else {
                toast.error("Please check your credentials and try again.");
                throw new Error('Failed to add data');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const deleteDataSource = createAsyncThunk(
    'dataSource/delete',
    async (data, { dispatch }) => {
        const type = 'delete';
        const url = `${DATA_SOURCE_APIS.delete}/${data}`;
        try {
            const response = await fetchUrl({ type, url, data, config: configObj });
            if (response.status == '204') {
                dispatch(fetchDataSource())
                return data;
            } else {
                throw new Error('While Delete dataSource api called');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const syncDbDataSource = createAsyncThunk(
    'dataSource/syncdb',
    async (data, { dispatch }) => {
        const type = 'put';
        const url = DATA_SOURCE_APIS.syncdb.replace("<datasource_id>", data.datasource_id)
        try {
            const response = await fetchUrl({ type, url, data: null, config: configObj });
            if (response?.status === 200) {
                dispatch(fetchDataSource())
                return response;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const getTablesDataSource = createAsyncThunk(
    'dataSource/tables',
    async (data) => {
        const type = 'get';
        const url = DATA_SOURCE_APIS.tables.replace("<datasource_id>", data.datasource_id)

        try {
            const response = await fetchUrl({ type, url, data, config: configObj });

            if (response?.status === 200) {
                return response.data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const syncTablesDataSource = createAsyncThunk(
    'dataSource/synctables',
    async (data) => {
        const type = 'put';
        const url = DATA_SOURCE_APIS.synctables.replace("<datasource_id>", data.id);

        try {
            const response = await fetchUrl({ type, url, data: { tables: data.tables }, config: configObj });

            if (response?.status === 200) {
                return response;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const getTablesDescriptionDataSource = createAsyncThunk(
    'dataSource/gettablesdescription',
    async (data) => {
        const type = 'get';
        const url = `/datasource/${data.datasource_id}/table/${data.datasource_table_id}/columns/list/`;

        try {
            const response = await fetchUrl({ type, url, data, config: configObj });

            if (response?.status === 200) {
                return response.data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const updateTablesDescriptionDataSource = createAsyncThunk(
    'dataSource/updatetablesdescription',
    async (data) => {
        const type = 'patch';
        const url = `/datasource/${data.datasource_id}/table/${data.datasource_table_id}/columns/`;

        try {
            const response = await fetchUrl({ type, url, data: data.body, config: configObj });


            if (response?.status === 200) {
                toast.success("Description updated successfully!");
                return data.body;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const editDataSource = createAsyncThunk(
    'dataSource/editdataSource',
    async (data) => {
        const type = 'put';
        const url = `/datasource/${data.datasource_id}/`;
        try {
            const response = await fetchUrl({ type, url, data: data.body, config: configObj });

            if (response == '') {
                toast.success("Database Udpated successfully!");
                return data;
            } else {
                throw new Error('While edit dataSource api called');
            }
        } catch (error) {
            throw error;
        }
    }
);

// Define the initial state
const initialState = {
    data: [],
    tables: [],
    tabledescription: [],
    status: 'idle',
    statuscode: 0,
    error: null,
};

// Create the slice
const dataSourceSlice = createSlice({
    name: 'dataSource',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Add Data Source
            .addCase(addDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuscode = 1;
                // state.data.push(action.payload);
            })
            .addCase(addDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Edit Data Source
            .addCase(editDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(editDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuscode = 1;
                const index = state.data.results.findIndex(item => item.id === action.payload.datasource_id);
                if (index !== -1) {
                    state.data.results[index] = action.payload.body;
                }
            })
            .addCase(editDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Delete Data Source
            .addCase(deleteDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data.results = state.data.results.filter((d) => d.id !== action.payload)
            })
            .addCase(deleteDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Tables Data Source
            .addCase(getTablesDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTablesDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tables = action.payload
            })
            .addCase(getTablesDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // sync Tables Data Source
            .addCase(syncTablesDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(syncTablesDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(syncTablesDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Table Description  Data Source
            .addCase(getTablesDescriptionDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTablesDescriptionDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tabledescription = action.payload;
            })
            .addCase(getTablesDescriptionDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Update Table Description  Data Source
            .addCase(updateTablesDescriptionDataSource.pending, (state) => {
                state.status = 'loading';
                state.statuscode = 2;
            })
            .addCase(updateTablesDescriptionDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.statuscode = 1;
            })
            .addCase(updateTablesDescriptionDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.statuscode = 0;
                state.error = action.error.message;
            })
            // Sync DB Data Source
            .addCase(syncDbDataSource.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(syncDbDataSource.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(syncDbDataSource.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default dataSourceSlice.reducer;