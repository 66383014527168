import axios from "axios";
import qs from "qs";
import { KEYS } from "../utils/constant";
import { getToken, LocalStorage } from "../utils/localStorage";
import toast from "react-hot-toast";
import { HTTP_METHODS, ERROR_CODES } from "../utils/constant";
const baseUrl = `${process.env.REACT_APP_API_URL}/`;

let cache = [];

const cancel = [];

const ACTION_HANDLERS = {
    [HTTP_METHODS?.GET]: (baseUrl, url, data, headers) => {
        let queryUrl = url;
        if (data) {
            const query = qs.stringify(data);
            if (query) {
                queryUrl += `?${query}`;
            }
        }
        return axios.get(baseUrl + queryUrl, {
            cancelToken: new axios.CancelToken((cToken) => {
                cancel.push({ url, cToken });
            }),
            headers,
        });
    },

    [HTTP_METHODS?.DELETE]: (baseUrl, url, data, headers) =>
        axios.delete(baseUrl + url, {
            headers,
            data,
        }),

    [HTTP_METHODS?.POST]: (baseUrl, url, data, headers, responseType) =>
        axios.post(baseUrl + url, data, {
            headers,
            responseType: responseType, // Pass the responseType here
        }),

    [HTTP_METHODS?.PATCH]: (baseUrl, url, data, headers) =>
        axios.patch(baseUrl + url, data, {
            headers,
        }),

    [HTTP_METHODS?.PUT]: (baseUrl, url, data, headers) =>
        axios.put(baseUrl + url, data, {
            headers,
        }),
};

function setHeaders({
    contentType,
    authToken = true,
    isServer = false,
    userAgent,
}) {
    const headers = {};

    if (isServer) {
        delete axios.defaults.headers.common.Authorization;
    }

    if (authToken && !isServer) {
        const token = getToken();
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    }

    headers[KEYS.deviceId] = LocalStorage.get(KEYS.deviceId);
    headers[KEYS.domain] = LocalStorage.get(KEYS.domain);
    headers[KEYS.sub] = LocalStorage.get(KEYS.sub);
    headers[KEYS.authType] = LocalStorage.get(KEYS.authType);

    if (userAgent) {
        headers["User-Agent"] = userAgent;
    }

    if (contentType) {
        headers["Content-Type"] = contentType;
        headers.Accept = "application/json";
    }

    return headers;
}

function handleError(error) {
    cache = [];
    const { response = {} } = error || {};
    const { status, data } = response;

    const statusHandlers = {
        500: () => toast.error("Internal Server Error"),
        403: () => {
            window.location.href = "/auth/sign-in";
            localStorage.clear();
            return false;
        },
        401: () => {
            toast.error(data?.error || "Unauthorized");
            if (typeof window !== "undefined" && !data?.DATA?.hasOwnProperty("confirmed")) {
                LocalStorage.clean();
                return false;
            }
            return false;
        },
        400: () => Promise.reject(response),
    };


    if (response.status === ERROR_CODES?.INTERNAL_SERVER_ERROR) {
        toast.error("Internal Server Error");
    }

    if (response.status === ERROR_CODES?.BAD_REQUEST) {
        toast.error(response?.data?.error ? response?.data?.error : response?.data?.message);
    }

    if (response.status === ERROR_CODES?.FORBIDDEN) {
        window.location.href = "/auth/sign-in";
        localStorage.clear();
        return false;
    }

    if (status in statusHandlers) {
        return statusHandlers[status]();

    }

    if (data?.error) {
        const errors = data.error;
        if ("non_field_errors" in errors && Array.isArray(errors["non_field_errors"])) {
            return Promise.reject(errors["non_field_errors"][0]);
        } else {
            return Promise.reject(errors);
        }
    }

    return Promise.reject(response);
}

const cacheHandler = (url, { shouldRefetch, handleCache = true }) => {
    if (!shouldRefetch && handleCache) {
        if (cache.includes(url)) {
            const controller = cancel.filter((index) => index.url === url);
        } else {
            cache.push(url);
        }
    }
};

const fetchUrl = ({ type, url, data = {}, config = {}, responseType = 'json' }) => {
    let { baseUrl, ...restConfig } = config;
    if (!baseUrl) {
        baseUrl = `${process.env.REACT_APP_API_URL}/`;
    }
    if (url.indexOf('/file/') > -1 || url.indexOf('/web-components/') > -1) {
        baseUrl = `${process.env.REACT_APP_URL}`;
    }


    const token = getToken();
    if (token) {
        restConfig.authToken = true;
    }

    const headers = setHeaders(restConfig);
    cacheHandler(url, restConfig);
    const handler = ACTION_HANDLERS[type.toUpperCase()];

    return handler(baseUrl, url, data, headers, responseType)
        .then((response) => Promise.resolve(response.data ? response.data : response))
        .catch(handleError);
};

export default fetchUrl;
