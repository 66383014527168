const initialState = {
    SIGN_UP: {
        title: true,
        logo: true,
        first_name: true,
        last_name: true,
        user_name: true,
        email: true,
        password: true,
        confirm_password: true,
        company_name: true,
        terms_and_conditions: true,
        privacy_policy: true,
        gender: false,
        country: false,
        state: false,
        city: false,
        zip_code: false,
        phone_number: false,
        how_did_you_learn_about_us: false,
        otp: false,
        captcha: false,
        google: false,
        linked_in: false,
        facebook: false,
        need_support: true
    },
    SIGN_IN: {
        title: true,
        logo: true,
        otp: false,
        email: true,
        password: true,
        confirm_password: true,
        phone_number: false,
        need_support: true
    },
    FORGET_PASSWORD: {
        title: true,
        logo: true,
        otp: false,
        email: true,
        password: true,
        confirm_password: true,
        phone_number: false,
        need_support: true
    },
    RESET_PASSWORD: {
        title: true,
        logo: true,
        email: true,
        otp: false,
        password: true,
        confirm_password: true,
        phone_number: false,
        captcha: false,
        need_support: true
    },
    VERIFICATION_OTP: {
        title: true,
        logo: true,
        otp: true,
        email: true,
        phone_number: true,
    }
};

export default initialState;
