import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    chatbotName: "Ai Assistant",
    welcomeMessage: "AI Assistant answers instantly",
};

export const aiChatBot = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setChatBotName: (state, action) => {
            state.chatbotName = action.payload || "Ai Assistant";
        },
        setWelcomeMessage: (state, action) => {
            state.welcomeMessage = action.payload || "AI Assistant answers instantly";
        },
    },
});

export const {
    setChatBotName,
    setWelcomeMessage,
} = aiChatBot.actions;

export default aiChatBot.reducer;
