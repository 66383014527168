import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Provider } from "react-redux";
import { store, persistor } from './store/index';
import { AuthProvider } from "./utils/AuthContext";
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={<div>Loading persisted state...</div>} persistor={persistor}>
        <AuthProvider>
          <Router>
            <Suspense fallback={<div>Loading application...</div>}>
              <App />
            </Suspense>
          </Router>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);
