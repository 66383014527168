import React from 'react'
import "../../styles/welcomescreen.css"
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

function BannerImageWithBottomButton({ backgroundimage = '', title = "", onclick = '', btntext = 'Connect data stream' }) {
  const containerStyle = {
    backgroundImage: `url(${backgroundimage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };
  return (
    <div className="bannerimage_container" >

      <div className="bannerimage_context" style={containerStyle}>
        {
          title && <div>
            <h3>{title}</h3>
          </div>
        }

        <div className='bottom_button'>
          <Button className="ms-2" onClick={onclick}>
            {btntext}
          </Button>
        </div>
      </div>
    </div>
  )
}
BannerImageWithBottomButton.propTypes = {
  backgroundimage: PropTypes.string,
  title: PropTypes.string,
  onclick: PropTypes.func,
  btntext: PropTypes.string,
};

export default BannerImageWithBottomButton