import { Link, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const Breadcrumb = ({ routes }) => {
  const location = useLocation();

  const breadcrumbs = useMemo(() => {
    const pathnames = location.pathname.split('/').filter(x => x);
    return [
      { path: '/', breadcrumb: 'Home' },
      ...pathnames.map((value, index) => {
        const path = `/${pathnames.slice(0, index + 1).join('/')}`;
        const route = routes.find(route => {
          const matchPath = route.path.replace(/:[^\s/]+/g, '[^/]+');
          const regex = new RegExp(`^${matchPath}$`);
          return path.match(regex);
        }) || {};
        return {
          path,
          breadcrumb: route.breadcrumb || value,
        };
      }),
    ];
  }, [location.pathname, routes]);

  return (
    <nav>
      {breadcrumbs.map(({ path, breadcrumb }, index) => (
        <span key={path}>
          {index > 0 && ' / '}
          {index === breadcrumbs.length - 1 ? (
            breadcrumb
          ) : (
            <Link to={path}>{breadcrumb}</Link>
          )}
        </span>
      ))}
    </nav>
  );
};

export default Breadcrumb;
